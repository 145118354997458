import React from 'react'
import TextContent from './TextContent'

const Content = () => {
    return (
        <div className='w-full max-w-[1200px] flex flex-col items-center justify-start gap-14 lg:gap-[96px] py-14 lg:py-20 lg:pb-28 pb-20 xl:px-0 md:px-20 sm:px-16 px-8'>

            {/* <h1 className="text-[#7FA0AF] text-3xl lg:text-[56px] font-normal text-start max-w-full leading-[68px]">
            </h1> */}

            <p className="text-base lg:text-[24px] font-normal text-[#4989A7] text-start max-w-full leading-8">
                Welcome to Offline Protocol. We are a pioneering organization dedicated to reshaping the landscape of micro-finance services and communication infrastructure for underserved communities worldwide. Our core mission is to bridge the digital divide by delivering innovative, technology-driven solutions that ensure everyone, regardless of location or socioeconomic status, has access to essential financial services and reliable communication channels.
            </p>

            <TextContent heading={'Our Story'} >
                <p className="text-base lg:text-[24px]  font-normal text-[#4989A7] text-start max-w-full leading-8">
                    Founded on the belief that everyone deserves the opportunity to participate in the global economy, Offline Protocol was created to address the significant challenges faced by billions of people who remain unbanked and digitally disconnected. Our team shares a diverse background in technology, finance, social impact, and military, and came together with a shared goal: to create a more inclusive and connected world.
                </p>
            </TextContent>

            <TextContent heading={'What We Do'} >
                <p className="text-base lg:text-[24px]  font-normal text-[#4989A7] text-start max-w-full leading-8">
                    Offline Protocol leverages offline networking solutions and blockchain technology to provide a robust infrastructure that empowers communities. Our suite of products and services is designed to facilitate:
                    <ul role="list" class="marker:text-[#4989A7] list-disc pl-5 space-y-1 leading-9 ">
                        <li>
                            Financial Inclusion: We will offer secure, accessible, and user-friendly financial services products that cater to the needs of the unbanked and underbanked populations. Our solutions will include decentralized finance (DeFi) tools, digital wallets, and micro-lending platforms, all aimed at providing economic opportunities for those traditionally excluded from the financial system.
                        </li>
                        <li>
                            Communication Infrastructure: We are developing and deploying resilient offline communication networks that operate even in areas with limited or no internet connectivity. By using mesh networks and other innovative technologies, we ensure that people can stay connected, share information, and access critical services regardless of their location.
                        </li>
                        <li>
                            Crypto Accessibility: Our ecosystem makes cryptocurrency easy to understand and use, opening up new avenues for investment, savings, and financial growth. We will provide educational resources, user-friendly interfaces, and support systems to help individuals navigate the world of digital assets confidently.
                        </li>
                        <li>
                            Verifiable Ownership: Through blockchain technology, we create a transparent and immutable record of ownership for digital and physical assets. This ensures that individuals can prove ownership, transfer assets securely, and participate in the global economy with confidence.
                        </li>
                    </ul>
                </p>
            </TextContent>

            <TextContent heading={'Join Us'} >
                <p className="text-base lg:text-[24px]  font-normal text-[#4989A7] text-start max-w-full leading-8">
                    We are always looking for passionate individuals and partners to join us in our mission. Whether you are a technology enthusiast, a social impact advocate, or someone who shares our vision of a more inclusive world, we invite you to connect with us. Together, we can build a brighter future where everyone has the tools and opportunities they need to succeed.                </p>
            </TextContent>

        </div>
    )
}

export default Content
