import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import GeneralHero from '../About/components/hero'
import Content from '../About/components/Content/Content'
import Footer from '../home/components/footer/Footer'
import ContentMission from './components/ContentMission'

const Mission = () => {
    return (
        <div className='w-full flex flex-col items-center justify-start min-h-screen pt-3'>
            <NavigationBar isAbout={true} />
            <GeneralHero title={'Our Mission'}  desc={'Bridging the Digital Divide, Empowering Communities'}/>
            <ContentMission />
            <Footer />
        </div>
        )
}

export default Mission
