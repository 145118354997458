import React from 'react'
import TextContent from '../../About/components/Content/TextContent'

const ContentMission = () => {
    return (
        <div className='w-full max-w-[1200px] flex flex-col items-center justify-start gap-14 lg:gap-[96px] py-14 lg:py-20 lg:pb-28 pb-20 xl:px-0 md:px-20 sm:px-16 px-8'>

            {/* <h1 className="text-[#7FA0AF] text-3xl lg:text-[56px] font-normal text-start max-w-full leading-[68px]">
                Bridging the Digital Divide, Empowering Communities
            </h1> */}

            <p className="text-base lg:text-[24px] font-normal text-[#4989A7] text-start max-w-full leading-8">
                At Offline Protocol, our mission is clear and unwavering: to help the unbanked, enable communication where it matters, make crypto more accessible, and create a global culture of verifiable ownership. We are committed to leveraging technology to drive financial inclusion and communication infrastructure for underserved communities worldwide.            </p>

            {/* OUR COMMITMENT */}
            <TextContent heading={'Our Commitment'} >
                <p className="text-base lg:text-[24px] font-normal text-[#4989A7] text-start max-w-full">
                    <ol role="list" class="marker:text-[#4989A7] list-decimal pl-5  space-y-8 leading-9 ">
                        <li>
                            Helping the Unbanked: We are dedicated to providing financial services to those who have been traditionally excluded from the financial system. Through our decentralized financial tools and digital wallets, we offer secure and accessible ways for individuals to save, invest, and manage their finances.
                        </li>
                        <li>

                            Enabling Communication Where It Matters: We recognize the importance of reliable communication for personal and community development. Our resilient communication networks ensure that people can stay connected and access essential services, even in remote and underserved areas.                        </li>
                        <li>
                            Making Crypto More Accessible: We strive to demystify cryptocurrency and make it accessible to everyone. By providing educational resources, user-friendly platforms, and robust support systems, we empower individuals to confidently participate in the digital economy.
                        </li>
                        <li>
                            Creating a Global Culture of Verifiable Ownership: Through blockchain technology, we enable transparent and secure ownership records for digital and physical assets. This fosters trust, reduces fraud, and ensures that individuals can prove and transfer ownership with confidence.
                        </li>
                    </ol>
                </p>
            </TextContent>

            {/* OUR APPROACH */}
            <TextContent heading={'Our Approach'} >
                <p className="text-base lg:text-[24px] font-normal text-[#4989A7] text-start max-w-full leading-8">
                    We believe in the power of technology to transform lives and communities. Our approach is rooted in:
                    <ul role="list" class="marker:text-[#4989A7] list-disc pl-5  space-y-8 leading-9">
                        <li>
                            Innovation: Continuously pushing the boundaries of what is possible with blockchain, edge computing, and offline networks to deliver cutting-edge solutions.                        </li>
                        <li>
                            Inclusivity: Designing our products and services to be accessible and user-friendly, ensuring that everyone, regardless of their technical expertise or background, can benefit.                        </li>
                        <li>
                            Collaboration: Partnering with local communities, organizations, and stakeholders to understand their unique needs and co-create solutions that are impactful and sustainable.                        </li>
                        <li>
                            Education: Providing comprehensive educational resources to empower individuals with the knowledge and skills needed to navigate and thrive in the digital economy.
                        </li>
                    </ul>
                </p>
            </TextContent>


            {/* OUR VALUES */}
            <TextContent heading={'Our Values'} >
                <p className="text-base lg:text-[24px] font-normal text-[#4989A7] text-start max-w-full leading-8">
                    Empowerment: We are committed to empowering individuals and communities with the tools and opportunities they need to succeed.
                    <ul role="list" class="marker:text-[#4989A7] list-disc pl-5 space-y-8 leading-9">
                        <li>
                            Innovation: We strive to innovate and stay at the forefront of technology to deliver the best solutions to our users.
                        </li>
                        <li>

                            Inclusivity: We believe in creating inclusive solutions that cater to the needs of diverse populations.
                        </li>
                        <li>
                            Integrity: We operate with the highest standards of integrity, ensuring transparency, security, and trust in everything we do.
                        </li>
                    </ul>
                </p>
            </TextContent>

            {/* LOOKING AHEAD */}
            <TextContent heading={'Looking Ahead'} >
                <p className="text-base lg:text-[24px] font-normal text-[#4989A7] text-start max-w-full leading-8">
                    Our mission is a continuous journey. As we look to the future, we remain dedicated to expanding our reach, improving our solutions, and making a lasting impact on the lives of those we serve. We envision a world where financial inclusion, reliable communication, and verifiable ownership are accessible to all, fostering economic empowerment and connectivity on a global scale.
                </p>
            </TextContent>

        </div>
    )
}

export default ContentMission
