import React from 'react'
import GeneralHero from './components/hero'
import NavigationBar from '../../components/NavigationBar'
import Content from './components/Content/Content'
import Footer from '../home/components/footer/Footer'

const About = () => {
    return (
        <div className='w-full flex flex-col items-center justify-start min-h-screen pt-3'>
            <NavigationBar isAbout={true} />
            <GeneralHero title={'About Offline Protocol'} desc={'Empowering Connectivity, Financial Inclusion, and Global Ownership'} />
            <Content />
            <Footer />
        </div>
    )
}

export default About
