import React from 'react'

const GeneralHero = ({ title, desc }) => {
    return (
        <div className="bg-[#4989A7] lg:min-h-[600px]  rounded-[8px] flex justify-center flex-col items-center md:px-20 sm:px-16 px-8  xl:px-36 relative h-full w-full py-48 lg:py-0 max-w-[95%] lg:max-w-[99%] ">
            <div className="flex flex-col items-start justify-start gap-6 sm:gap-10 w-full max-w-[1200px] ">
                {/* general hero title used in  mission and about page */}
                <h1 className="text-[#F4FCFF] md:text-5xl text-3xl lg:text-[60px] font-bold">{title}</h1>
                <h1 className="text-[#F4FCFF] md:text-2xl text-xl lg:text-[30px] italic">{desc}</h1>
            </div>
        </div>

    )
}

export default GeneralHero
