import React from 'react'

const TextContent = ({ heading, children }) => {
    return (
        <div className='flex flex-col items-start justify-start gap-6 lg:gap-10 w-full'>
            <h1 className="text-3xl lg:text-[30px] font-bold text-[#4989A7]">{heading}</h1>

            {children}
        </div>
    )
}

export default TextContent
